import React from 'react'
import {Link} from 'gatsby'

const PageBanner = ({pageTitle, homePageUrl, homePageText, activePageText}) => {
    return (
        <div>
            <div className="container">
                <div className="page-title-content">
                    <ul>
                        <li>
                            <Link to={homePageUrl}>
                                {homePageText}
                            </Link>
                        </li>
                        <li>{activePageText}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default PageBanner;